@media only screen and (min-width: 431px) {
  .switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 28px;
  }

  .switch input {
    display: none;
  }

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background-color: #71A76C;
    transition: .4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #43706A;
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }

  .switch-conteiner{
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .icon-light-mode {
    color: #71A76C;
    font-size: 20px;
  }

  .icon-dark-mode {
    color: #43706A;
    font-size: 20px;
  }
}
  
@media only screen and (max-width: 430px) {
  .switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 28px;
  }

  .switch input {
    display: none;
  }

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background-color: #71A76C;
    transition: .4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #43706A;
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }

  .switch-conteiner{
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 10px;
  }

  .icon-light-mode {
    color: #71A76C;
    font-size: 20px;
  }

  .icon-dark-mode {
    color: #43706A;
    font-size: 20px;
  }
}