/* Navbar.css */

@media only screen and (min-width: 431px) {
  .navbar {
    width: 150px;
    height: 100vh;
    padding: 10px;
    left: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "BigNoodleTitling", sans-serif;
    font-size: 30px;
    gap: 2%;
  }

  .navbar a {
    text-decoration: none;
    display: flex;
    text-align: center;
    align-items: center;
    padding: 14px;
  }

  .navbar a:hover {
    border-radius: 10px;
  }

  .navbar a.active {
    border-radius: 10px;
  }

  /* -------------------------------------- */
  /* -------------- DARK MODE -------------*/
  /* -------------------------------------- */

  .navbar-dark-mode {
    background-color: #060a06;
    color: #f7fff7;
  }

  .navbar-dark-mode a {
    color: #f7fff7;
  }

  .navbar-dark-mode a.active {
    background-color: #71a76c;
  }

  .navbar-dark-mode a:hover {
    background-color: #555;
  }
  /* --------------------------------------- */

  /* --------------------------------------- */
  /* -------------- LIGHT MODE -------------*/
  /* --------------------------------------- */

  .navbar-light-mode {
    background-color: #d0e1d0;
    color: #0e160e;
  }

  .navbar-light-mode a {
    color: #0e160e;
  }
  .navbar-light-mode a.active {
    background-color: #71a76c;
    color: #f5f9f5;
  }
  .navbar-light-mode a:hover {
    background-color: #f5f9f5;
    color: #0e160e;
  }
  /* --------------------------------------- */
}

@media only screen and (max-width: 430px) {
  .navbar {
    display: flex;
    flex-direction: row;
    font-family: "BigNoodleTitling", sans-serif;
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    z-index: 1000;
  }

  .navbar a {
    text-decoration: none;
    display: flex;
    flex-grow: 1;
    text-align: center;
    align-items: center;
    padding: 14px;
  }

  /* -------------------------------------- */
  /* -------------- DARK MODE -------------*/
  /* -------------------------------------- */

  .navbar-dark-mode {
    background-color: #060a06;
    color: #f7fff7;
  }

  .navbar-dark-mode a {
    color: #f7fff7;
  }

  .navbar-dark-mode a.active {
    background-color: #71a76c;
  }

  /* --------------------------------------- */

  /* --------------------------------------- */
  /* -------------- LIGHT MODE -------------*/
  /* --------------------------------------- */

  .navbar-light-mode {
    background-color: #d0e1d0;
    color: #0e160e;
  }

  .navbar-light-mode a {
    color: #0e160e;
  }
  .navbar-light-mode a.active {
    background-color: #71a76c;
    color: #f5f9f5;
  }

  /* --------------------------------------- */
}
